import {Component} from '@angular/core';
import {AuthenticationService} from "../../auth/authentication.service";
import {Router} from "@angular/router";
import {KeycloakService} from "keycloak-angular";

@Component({
  selector: 'app-unauthorized',
  standalone: true,
  imports: [],
  templateUrl: './unauthorized.component.html',
  styleUrl: './unauthorized.component.scss'
})
export class UnauthorizedComponent {

  constructor(
    private readonly router: Router,
    protected readonly keycloak: KeycloakService,
    private readonly auth: AuthenticationService
  ) {
    auth.isLoggedIn().then(isLoggedIn => {
      if (!isLoggedIn) {
        this.keycloak.login({
          redirectUri: window.location.origin,
        });
      }
    })
  }

}
