import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot,} from "@angular/router";
import {KeycloakAuthGuard, KeycloakService} from "keycloak-angular";
import {AuthenticationService} from "./authentication.service";
import {Authority} from "./authority.constants";

@Injectable({
  providedIn: "root",
})
export class AuthGuard extends KeycloakAuthGuard {
  constructor(
    protected override readonly router: Router,
    protected readonly keycloak: KeycloakService,
    protected readonly auth: AuthenticationService
  ) {
    super(router, keycloak);
  }

  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    // Force the user to log in if currently unauthenticated.
    if (!this.authenticated) {
      await this.keycloak.login({
        redirectUri: window.location.origin + state.url,
      });
      return false; // Ensure access is denied until authenticated
    }

    // Get the roles required from the route.
    const requiredRoles = route.data["roles"];

    // Check if user has any roles defined in the Authority enum.
    const userHasRole = this.auth.hasAnyAuthority(Object.values(Authority));

    if (!userHasRole) {
      await this.router.navigate(['/unauthorized']); // Ensure this route exists in your routing module
      return false;
    }

    // Allow the user to proceed if no additional roles are required to access the route.
    if (!Array.isArray(requiredRoles) || requiredRoles.length === 0 || this.auth.hasAnyAuthority(requiredRoles)) {
      return true;
    }

    await this.router.navigate(['/']); // Redirect to home if not authorized
    return false;
  }
}
