<section class="py-5">
  <div class="container min-vh-50 py-6 d-flex justify-content-center align-items-center" style="max-width:1920px">
    <div class="row">
      <div class="col-md-12 text-center">
        <div class="lc-block mb-4">
          <div editable="rich">
            <h1 class="fw-bold display-1">404</h1>
          </div>
        </div>
        <div class="lc-block">
          <div editable="rich">
            <p class="h2">Désolé, nous ne trouvons pas la page que vous recherchez.</p>
            <p class="lead">Cliquez sur le bouton ci-dessous pour revenir à la page d'accueil.</p>
          </div>
        </div>
        <div class="lc-block">
          <a class="btn btn-primary" href="#" role="button">Page d'accueil</a>
        </div><!-- /lc-block -->
      </div><!-- /col -->
    </div>
  </div>
</section>
