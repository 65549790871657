import {Routes} from '@angular/router';
import {AuthGuard} from "./auth/auth.guard";
import {Authority} from "./auth/authority.constants";
import {UnauthorizedComponent} from "./errors/unauthorized/unauthorized.component";
import {BaseComponent} from "./layout/base/base.component";
import {PageNotFoundComponent} from "./errors/page-not-found/page-not-found.component";

export const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    data: {
      roles: [Authority.USER],
    },
    loadChildren: () => import(`./frontend/frontend-routing.module`).then(({FrontendRoutingModule}) => FrontendRoutingModule),
  },
  {
    path: 'admin',
    data: {
      roles: [Authority.ADMIN],
    },
    canActivate: [AuthGuard],
    loadChildren: () => import(`./backoffice/backoffice-routing.module`).then(({BackofficeRoutingModule}) => BackofficeRoutingModule),
  },
  {
    path: '',
    component: BaseComponent,
    children: [
      {
        path: 'unauthorized',
        component: UnauthorizedComponent
      }
    ]
  },
  {
    path: '**',
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: PageNotFoundComponent
      }
    ]
  }
];
